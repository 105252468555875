import cardValidator from 'card-validator'
import { envKeys } from 'config'
import { useQuery } from 'react-query'
import { HTTPMethod, HttpRequest } from 'utils/apiUtils'
import validateCBU from 'utils/cbuValidator'
import { validateCuil } from 'utils/cuilValidator'
import * as yup from 'yup'

export const initPaymentSchema = yup
  .object({
    email: yup.string().min(1, 'Requerido').email('Email no válido').required('Requerido'),
  })
  .required()

export type InitPaymentType = yup.InferType<typeof initPaymentSchema>

const initPayment = async (values: InitPaymentType) => {
  const bearer = `Bearer ${localStorage.getItem('token')}`
  const request: HttpRequest = {
    method: HTTPMethod.POST,
    headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
    body: JSON.stringify(values),
  }
  const response = await fetch(`${envKeys.api.url}/payments/init`, request)

  if (!response.ok) {
    const error = await response.json()
    throw new Error(error?.message ?? 'failed to fetch')
  }
}

const initAnonPayment = async (values: InitPaymentType, name: string) => {
  //const bearer = `Bearer ${localStorage.getItem('token')}`

  const request: HttpRequest = {
    method: HTTPMethod.POST,
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify({ ...values, payName: name }),
  }
  const response = await fetch(`${envKeys.api.url}/payments/int-by-name`, request)

  if (!response.ok) {
    const error = await response.json()
    throw new Error(error?.message ?? 'failed to fetch')
  }
  return response.json()
}

export enum BankAccountType {
  Checking = 'checking',
  Savings = 'savings',
}

export const BankAccountTypeLabel = {
  [BankAccountType.Checking]: 'Cuenta Corriente',
  [BankAccountType.Savings]: 'Cuenta de Ahorro',
} as const

export enum PaymentType {
  Deposit = 'deposit',
  Transfer = 'transfer',
  CreditCard = 'creditCard',
  Cash = 'cash',
}

export const PaymentTypeLabel = {
  [PaymentType.Deposit]: 'Depósito',
  [PaymentType.Transfer]: 'Transferencia',
  [PaymentType.CreditCard]: 'Tarjeta de Crédito',
  [PaymentType.Cash]: 'Efectivo',
}

export enum CreditCardType {
  VISA = 'visa',
  MC = 'mc',
  AMEX = 'amex',
}

export const CreditCardTypeLabel = {
  [CreditCardType.VISA]: 'Visa',
  [CreditCardType.MC]: 'Master Card',
  [CreditCardType.AMEX]: 'American Express',
}

export const confirmPaymentSchema = yup
  .object({
    paymentType: yup
      .mixed<PaymentType>()
      .oneOf([PaymentType.Deposit, PaymentType.Transfer, PaymentType.CreditCard, PaymentType.Cash])
      .required('Requerido'),

    creditCardType: yup
      .mixed<CreditCardType>()
      .oneOf([CreditCardType.VISA, CreditCardType.MC, CreditCardType.AMEX])
      .when('paymentType', {
        is: (option: PaymentType) => [PaymentType.CreditCard].includes(option),
        then: (schema) => schema.required('Requerido'),
      }),
    creditCardNumber: yup
      .string()
      .min(1, 'Requerido')
      .when('paymentType', {
        is: (option: PaymentType) => [PaymentType.CreditCard].includes(option),
        then: (schema) =>
          schema
            .test(
              'creditCardNumber',
              'El número de la tarjeta es inválido',
              (value) => !value || cardValidator.number(value).isValid,
            )
            .required('Requerido'),
      })
      .when('creditCardType', {
        is: (option?: CreditCardType) => (option ? true : false),
        then: (schema) =>
          schema
            .test('creditCardType', 'No coincide con el tipo de la tarjeta', (value, context) => {
              if (!value) return true

              const metadata = cardValidator.number(value)

              if (context.parent.creditCardType === CreditCardType.VISA) {
                return metadata.card?.type === 'visa'
              } else if (context.parent.creditCardType === CreditCardType.MC) {
                return metadata.card?.type === 'mastercard'
              } else if (context.parent.creditCardType === CreditCardType.AMEX) {
                return metadata.card?.type === 'american-express'
              }

              return false
            })
            .required('Requerido'),
      }),
    bankName: yup.string().when('paymentType', {
      is: (option: PaymentType) => [PaymentType.Deposit, PaymentType.Transfer, PaymentType.CreditCard].includes(option),
      then: (schema) => schema.min(1, 'Requerido').required('Requerido'),
    }),
    holderName: yup.string().min(1, 'Requerido').required('Requerido'),
    cbuNumber: yup.string().when('paymentType', {
      is: (option: PaymentType) => [PaymentType.Deposit, PaymentType.Transfer].includes(option),
      then: (schema) =>
        schema
          .min(1, 'Requerido')
          .test('cbuNumber', 'El CBU es inválido', (value) => !value || validateCBU(value))
          .required('Requerido'),
    }),
    alias: yup.string().optional(),
    accountNumber: yup.string().optional(),
    accountType: yup
      .mixed<BankAccountType>()
      .oneOf([BankAccountType.Checking, BankAccountType.Savings])
      .when('paymentType', {
        is: (option: PaymentType) => [PaymentType.Deposit, PaymentType.Transfer].includes(option),
        then: (schema) => schema.required('Requerido'),
      }),
    holderIdentityNumber: yup.string().when('paymentType', {
      is: (option: PaymentType) => [PaymentType.Deposit, PaymentType.Transfer, PaymentType.CreditCard].includes(option),
      then: (schema) =>
        schema
          .min(1, 'Requerido')
          .test('holderIdentityNumber', 'El CUIL/CUIT es inválido', (value) => !value || validateCuil(value))
          .required('Requerido'),
    }),
    deliveryAddress: yup.string().optional(),
    depositAmount: yup
      .number()
      .typeError('Valor inválido')
      .moreThan(0, 'Tiene que ser mayor a 0')
      .required('Requerido'),
    phoneNumber: yup.string().min(1, 'Requerido').required('Requerido'),
  })
  .required()

export type ConfirmPaymentType = yup.InferType<typeof confirmPaymentSchema>

const confirmPayment = async (paymentKey: string, values: ConfirmPaymentType) => {
  const request: HttpRequest = {
    method: HTTPMethod.POST,
    headers: new Headers({ 'content-type': 'application/json' }),
    body: JSON.stringify({ ...values, paymentKey }),
  }
  const response = await fetch(`${envKeys.api.url}/payments/confirm`, request)

  if (!response.ok) {
    const error = await response.json()
    throw new Error(error?.message ?? 'failed to fetch')
  }
}

export enum PaymentStatusType {
  Confirmed = 'confirmed',
  Approved = 'approved',
  Pending = 'pending',
  Paid = 'paid',
  Rejected = 'rejected',
}

interface RegisteredCustomer {
  name: string
  email: string
  wallet: string
  split: string
  id: string
  payments: boolean
  dollarValue?: number
}

export type PaymentInfoApi = ConfirmPaymentType & {
  customerID: string
  paymentRequestID: string
  status: PaymentStatusType
  usdtReceived?: number
  txHash?: string
  id: string
  createdAt: string
}

export interface GetCurrentPaymentApi {
  paymentInfo?: PaymentInfoApi
  registeredCustomer: RegisteredCustomer
}

const getCurrentPayment = async (paymentKey: string) => {
  const request: HttpRequest = {
    method: HTTPMethod.GET,
    headers: new Headers({ 'content-type': 'application/json' }),
  }
  const response = await fetch(`${envKeys.api.url}/payments/current?paymentKey=${paymentKey}`, request)

  if (!response.ok) {
    const error = await response.json()
    throw new Error(error?.message ?? 'failed to fetch')
  }

  const json = await response.json()

  return json as GetCurrentPaymentApi
}

const useGetCurrentPayment = ({
  paymentKey,
  enabled = true,
}: {
  paymentKey: string | undefined
  enabled?: boolean
}) => {
  return useQuery(['current-payment', paymentKey], () => getCurrentPayment(paymentKey!), {
    enabled: enabled && paymentKey ? true : false,
    refetchInterval: 1000,
  })
}

const listPayments = async (status: string) => {
  const bearer = `Bearer ${localStorage.getItem('token')}`
  const request: HttpRequest = {
    method: HTTPMethod.GET,
    headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
  }

  const response = await fetch(`${envKeys.api.url}/payments/list?status=${status}`, request)

  if (!response.ok) {
    const error = await response.json()
    throw new Error(error?.message ?? 'failed to fetch')
  }

  const json = await response.json()

  return json as PaymentInfoApi[]
}

const useListPayments = ({ status, enabled = true }: { status: string; enabled?: boolean }) => {
  return useQuery(['list-payments', status], () => listPayments(status), { enabled, refetchInterval: 1000 })
}

const changePaymentStatus = async (paymentInfoID: string, newStatus: PaymentStatusType) => {
  const bearer = `Bearer ${localStorage.getItem('token')}`
  const request: HttpRequest = {
    method: HTTPMethod.PUT,
    headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
    body: JSON.stringify({
      paymentInfoID,
      newStatus,
    }),
  }
  const response = await fetch(`${envKeys.api.url}/payments/change-status`, request)

  if (!response.ok) {
    const error = await response.json()
    throw new Error(error?.message ?? 'failed to fetch')
  }
}

export const updateDollarValueSchema = yup
  .object({
    newDollarValue: yup
      .number()
      .typeError('Valor inválido')
      .moreThan(0, 'Tiene que ser mayor a 0')
      .required('Requerido'),
  })
  .required()

export type UpdateDollarValueType = yup.InferType<typeof updateDollarValueSchema>

const updateDollarValue = async (values: UpdateDollarValueType) => {
  const bearer = `Bearer ${localStorage.getItem('token')}`
  const request: HttpRequest = {
    method: HTTPMethod.PUT,
    headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
    body: JSON.stringify({
      ...values,
    }),
  }
  const response = await fetch(`${envKeys.api.url}/payments/update-dollar-value`, request)

  if (!response.ok) {
    const error = await response.json()
    throw new Error(error?.message ?? 'failed to fetch')
  }
}

export {
  changePaymentStatus,
  confirmPayment,
  initPayment,
  updateDollarValue,
  useGetCurrentPayment,
  useListPayments,
  initAnonPayment,
}
