import { envKeys } from 'config'
import { useQuery } from 'react-query'
import { HTTPMethod, HttpRequest } from 'utils/apiUtils'
import { PaymentInfoApi } from './usePayments'

const listAdminPayments = async (status: string) => {
  const bearer = `Bearer ${localStorage.getItem('token')}`
  const request: HttpRequest = {
    method: HTTPMethod.GET,
    headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
  }

  const response = await fetch(`${envKeys.api.url}/admin/payments-list?status=${status}`, request)

  if (!response.ok) {
    const error = await response.json()
    throw new Error(error?.message ?? 'failed to fetch')
  }

  const json = await response.json()

  return json as PaymentInfoApi[]
}

const useAdminListPayments = ({ status, enabled = true }: { status: string; enabled?: boolean }) => {
  return useQuery(['list-payments', status], () => listAdminPayments(status), { enabled, refetchInterval: 1000 })
}

export { useAdminListPayments }
